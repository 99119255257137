<template>
  <base-selector
    :items="items"
    label="Task type"
    :initial-value="initialValue"
    @select="(newSelect) => $emit('select', newSelect, type)"
  />
</template>

<script>
  import BaseSelector from '@/components/base/selectors/BaseSelector';
  export default {
    name: 'BaseTaskSelector',
    components: { BaseSelector },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
      type: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style scoped>
</style>
